/* tslint:disable */
/* eslint-disable */
/**
 * Swimm\'s Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setBearerAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, BaseAPI, operationServerMap } from './base';
/**
 * AskSwimmBackendApi - axios parameter creator
 * @export
 */
export const AskSwimmBackendApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Increment AskSwimm feature usage
         * @param {string} workspaceId The workspace ID
         * @param {AllowanceRequest} allowanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceEndWorkspacesWorkspaceIdPost: (workspaceId, allowanceRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('apiAllowanceEndWorkspacesWorkspaceIdPost', 'workspaceId', workspaceId);
            // verify required parameter 'allowanceRequest' is not null or undefined
            assertParamExists('apiAllowanceEndWorkspacesWorkspaceIdPost', 'allowanceRequest', allowanceRequest);
            const localVarPath = `/api/allowance/end/workspaces/{workspaceId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(allowanceRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Increment AskSwimm feature usage
         * @param {string} workspaceId The workspace ID
         * @param {AllowanceRequest} allowanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceStartWorkspacesWorkspaceIdPost: (workspaceId, allowanceRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('apiAllowanceStartWorkspacesWorkspaceIdPost', 'workspaceId', workspaceId);
            // verify required parameter 'allowanceRequest' is not null or undefined
            assertParamExists('apiAllowanceStartWorkspacesWorkspaceIdPost', 'allowanceRequest', allowanceRequest);
            const localVarPath = `/api/allowance/start/workspaces/{workspaceId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(allowanceRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Increment feature usage
         * @param {string} workspaceId The workspace ID
         * @param {string} featureId The feature ID to validate for
         * @param {number} usageCount The number usage predicted in the request. Defaults to 1
         * @param {AllowanceRequest1} allowanceRequest1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementCap: (workspaceId, featureId, usageCount, allowanceRequest1, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('incrementCap', 'workspaceId', workspaceId);
            // verify required parameter 'featureId' is not null or undefined
            assertParamExists('incrementCap', 'featureId', featureId);
            // verify required parameter 'usageCount' is not null or undefined
            assertParamExists('incrementCap', 'usageCount', usageCount);
            // verify required parameter 'allowanceRequest1' is not null or undefined
            assertParamExists('incrementCap', 'allowanceRequest1', allowanceRequest1);
            const localVarPath = `/api/allowance/increment/workspaces/{workspaceId}/features/{featureId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"featureId"}}`, encodeURIComponent(String(featureId)))
                .replace(`{${"usageCount"}}`, encodeURIComponent(String(usageCount)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(allowanceRequest1, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AskSwimmBackendApi - functional programming interface
 * @export
 */
export const AskSwimmBackendApiFp = function (configuration) {
    const localVarAxiosParamCreator = AskSwimmBackendApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Increment AskSwimm feature usage
         * @param {string} workspaceId The workspace ID
         * @param {AllowanceRequest} allowanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceEndWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.apiAllowanceEndWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AskSwimmBackendApi.apiAllowanceEndWorkspacesWorkspaceIdPost']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Increment AskSwimm feature usage
         * @param {string} workspaceId The workspace ID
         * @param {AllowanceRequest} allowanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceStartWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.apiAllowanceStartWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AskSwimmBackendApi.apiAllowanceStartWorkspacesWorkspaceIdPost']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Increment feature usage
         * @param {string} workspaceId The workspace ID
         * @param {string} featureId The feature ID to validate for
         * @param {number} usageCount The number usage predicted in the request. Defaults to 1
         * @param {AllowanceRequest1} allowanceRequest1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementCap(workspaceId, featureId, usageCount, allowanceRequest1, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.incrementCap(workspaceId, featureId, usageCount, allowanceRequest1, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AskSwimmBackendApi.incrementCap']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * AskSwimmBackendApi - factory interface
 * @export
 */
export const AskSwimmBackendApiFactory = function (configuration, basePath, axios) {
    const localVarFp = AskSwimmBackendApiFp(configuration);
    return {
        /**
         *
         * @summary Increment AskSwimm feature usage
         * @param {string} workspaceId The workspace ID
         * @param {AllowanceRequest} allowanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceEndWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options) {
            return localVarFp.apiAllowanceEndWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Increment AskSwimm feature usage
         * @param {string} workspaceId The workspace ID
         * @param {AllowanceRequest} allowanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceStartWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options) {
            return localVarFp.apiAllowanceStartWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Increment feature usage
         * @param {string} workspaceId The workspace ID
         * @param {string} featureId The feature ID to validate for
         * @param {number} usageCount The number usage predicted in the request. Defaults to 1
         * @param {AllowanceRequest1} allowanceRequest1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementCap(workspaceId, featureId, usageCount, allowanceRequest1, options) {
            return localVarFp.incrementCap(workspaceId, featureId, usageCount, allowanceRequest1, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AskSwimmBackendApi - object-oriented interface
 * @export
 * @class AskSwimmBackendApi
 * @extends {BaseAPI}
 */
export class AskSwimmBackendApi extends BaseAPI {
    /**
     *
     * @summary Increment AskSwimm feature usage
     * @param {string} workspaceId The workspace ID
     * @param {AllowanceRequest} allowanceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AskSwimmBackendApi
     */
    apiAllowanceEndWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options) {
        return AskSwimmBackendApiFp(this.configuration).apiAllowanceEndWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Increment AskSwimm feature usage
     * @param {string} workspaceId The workspace ID
     * @param {AllowanceRequest} allowanceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AskSwimmBackendApi
     */
    apiAllowanceStartWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options) {
        return AskSwimmBackendApiFp(this.configuration).apiAllowanceStartWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Increment feature usage
     * @param {string} workspaceId The workspace ID
     * @param {string} featureId The feature ID to validate for
     * @param {number} usageCount The number usage predicted in the request. Defaults to 1
     * @param {AllowanceRequest1} allowanceRequest1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AskSwimmBackendApi
     */
    incrementCap(workspaceId, featureId, usageCount, allowanceRequest1, options) {
        return AskSwimmBackendApiFp(this.configuration).incrementCap(workspaceId, featureId, usageCount, allowanceRequest1, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} featureId The feature ID to validate for
         * @param {number} usageCount The number usage predicted in the request. Defaults to 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet: (workspaceId, featureId, usageCount, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet', 'workspaceId', workspaceId);
            // verify required parameter 'featureId' is not null or undefined
            assertParamExists('apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet', 'featureId', featureId);
            // verify required parameter 'usageCount' is not null or undefined
            assertParamExists('apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet', 'usageCount', usageCount);
            const localVarPath = `/api/allowance/validate/workspaces/{workspaceId}/features/{featureId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"featureId"}}`, encodeURIComponent(String(featureId)))
                .replace(`{${"usageCount"}}`, encodeURIComponent(String(usageCount)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} featureId The feature ID to validate for
         * @param {number} usageCount The number usage predicted in the request. Defaults to 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet: (workspaceId, featureId, usageCount, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet', 'workspaceId', workspaceId);
            // verify required parameter 'featureId' is not null or undefined
            assertParamExists('apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet', 'featureId', featureId);
            // verify required parameter 'usageCount' is not null or undefined
            assertParamExists('apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet', 'usageCount', usageCount);
            const localVarPath = `/api/allowance/validate/workspaces/{workspaceId}/features/{featureId}/{usageCount}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"featureId"}}`, encodeURIComponent(String(featureId)))
                .replace(`{${"usageCount"}}`, encodeURIComponent(String(usageCount)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotAgentPost: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/copilot/agent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotAuthGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/copilot/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/copilot/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotGithubAppMarketplacePost: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/copilot/github-app/marketplace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotGithubAppPost: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/copilot/github-app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} featureId The feature ID to validate for
         * @param {number} usageCount The number usage predicted in the request. Defaults to 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet(workspaceId, featureId, usageCount, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet(workspaceId, featureId, usageCount, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} featureId The feature ID to validate for
         * @param {number} usageCount The number usage predicted in the request. Defaults to 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet(workspaceId, featureId, usageCount, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet(workspaceId, featureId, usageCount, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotAgentPost(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.apiCopilotAgentPost(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.apiCopilotAgentPost']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotAuthGet(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.apiCopilotAuthGet(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.apiCopilotAuthGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotGet(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.apiCopilotGet(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.apiCopilotGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotGithubAppMarketplacePost(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.apiCopilotGithubAppMarketplacePost(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.apiCopilotGithubAppMarketplacePost']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotGithubAppPost(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.apiCopilotGithubAppPost(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.apiCopilotGithubAppPost']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration, basePath, axios) {
    const localVarFp = DefaultApiFp(configuration);
    return {
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} featureId The feature ID to validate for
         * @param {number} usageCount The number usage predicted in the request. Defaults to 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet(workspaceId, featureId, usageCount, options) {
            return localVarFp.apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet(workspaceId, featureId, usageCount, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} featureId The feature ID to validate for
         * @param {number} usageCount The number usage predicted in the request. Defaults to 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet(workspaceId, featureId, usageCount, options) {
            return localVarFp.apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet(workspaceId, featureId, usageCount, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotAgentPost(options) {
            return localVarFp.apiCopilotAgentPost(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotAuthGet(options) {
            return localVarFp.apiCopilotAuthGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotGet(options) {
            return localVarFp.apiCopilotGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotGithubAppMarketplacePost(options) {
            return localVarFp.apiCopilotGithubAppMarketplacePost(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotGithubAppPost(options) {
            return localVarFp.apiCopilotGithubAppPost(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     *
     * @param {string} workspaceId The workspace ID
     * @param {string} featureId The feature ID to validate for
     * @param {number} usageCount The number usage predicted in the request. Defaults to 1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet(workspaceId, featureId, usageCount, options) {
        return DefaultApiFp(this.configuration).apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet(workspaceId, featureId, usageCount, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} workspaceId The workspace ID
     * @param {string} featureId The feature ID to validate for
     * @param {number} usageCount The number usage predicted in the request. Defaults to 1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet(workspaceId, featureId, usageCount, options) {
        return DefaultApiFp(this.configuration).apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet(workspaceId, featureId, usageCount, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    apiCopilotAgentPost(options) {
        return DefaultApiFp(this.configuration).apiCopilotAgentPost(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    apiCopilotAuthGet(options) {
        return DefaultApiFp(this.configuration).apiCopilotAuthGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    apiCopilotGet(options) {
        return DefaultApiFp(this.configuration).apiCopilotGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    apiCopilotGithubAppMarketplacePost(options) {
        return DefaultApiFp(this.configuration).apiCopilotGithubAppMarketplacePost(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    apiCopilotGithubAppPost(options) {
        return DefaultApiFp(this.configuration).apiCopilotGithubAppPost(options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function (configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.healthGet(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['HealthApi.healthGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration, basePath, axios) {
    const localVarFp = HealthApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet(options) {
            return localVarFp.healthGet(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    healthGet(options) {
        return HealthApiFp(this.configuration).healthGet(options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * SwimmApi - axios parameter creator
 * @export
 */
export const SwimmApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {AddEnterpriseRequest} addEnterpriseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEnterprise: (addEnterpriseRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'addEnterpriseRequest' is not null or undefined
            assertParamExists('addEnterprise', 'addEnterpriseRequest', addEnterpriseRequest);
            const localVarPath = `/api/add-enterprise`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(addEnterpriseRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * SwimmApi - functional programming interface
 * @export
 */
export const SwimmApiFp = function (configuration) {
    const localVarAxiosParamCreator = SwimmApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {AddEnterpriseRequest} addEnterpriseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEnterprise(addEnterpriseRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addEnterprise(addEnterpriseRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.addEnterprise']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * SwimmApi - factory interface
 * @export
 */
export const SwimmApiFactory = function (configuration, basePath, axios) {
    const localVarFp = SwimmApiFp(configuration);
    return {
        /**
         *
         * @param {AddEnterpriseRequest} addEnterpriseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEnterprise(addEnterpriseRequest, options) {
            return localVarFp.addEnterprise(addEnterpriseRequest, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * SwimmApi - object-oriented interface
 * @export
 * @class SwimmApi
 * @extends {BaseAPI}
 */
export class SwimmApi extends BaseAPI {
    /**
     *
     * @param {AddEnterpriseRequest} addEnterpriseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    addEnterprise(addEnterpriseRequest, options) {
        return SwimmApiFp(this.configuration).addEnterprise(addEnterpriseRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
