<script setup lang="ts">
import { SplitButton, Tag } from '@swimm/ui';
import EllipsisOption from '@/common/components/atoms/EllipsisOption.vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { gitProviderUtils, productEvents } from '@swimm/shared';

import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { CreationOptions } from '@/modules/core/workspace/home/composables/creationOptions';

defineProps({
  disabled: { type: Boolean, default: false },
  optionsDisabled: { type: Boolean, default: false },
});

const emit = defineEmits(['click', 'new-doc-click', 'new-playlist-click']);
const { reposStateData } = storeToRefs(useReposStore());

const analytics = useAnalytics();

const route = useRoute();
const repoId = computed(() => route.params.repoId);
const workspaceId = computed(() => route.params.workspaceId);

const { isGenAIDisabledForWorkspace, isProcessToDocDisabledForWorkspace } = storeToRefs(useWorkspaceStore());

async function getRepoDefaultBranchName() {
  const repoFromState = reposStateData.value[repoId.value as string];
  return repoFromState?.defaultBranch || '';
}

async function getBranch() {
  return route.params.branch || (await getRepoDefaultBranchName());
}
const isPrToDocVisible = computed(() =>
  gitProviderUtils.doesProviderSupportPRToDoc(reposStateData.value[repoId.value as string]?.provider)
);

function reportOptionSelected(option: 'New Doc' | 'PR' | 'Playlist' | 'Snippets') {
  analytics.track(productEvents.SELECTED_CREATE_ELLIPSIS_MENU_OPTION, {
    Option: option,
    'Repo ID': repoId.value,
    'Workspace ID': workspaceId.value,
  });
}

async function onNewDocClick() {
  reportOptionSelected('New Doc');
  emit('new-doc-click', {
    query: {},
    repoId: repoId.value,
    branch: await getBranch(),
  });
}

async function onNewPlaylistClick() {
  reportOptionSelected('Playlist');
  emit('new-playlist-click', {
    query: { action: 'playlist' },
    repoId: repoId.value,
    branch: await getBranch(),
  });
}

async function onPrToDocClick() {
  reportOptionSelected('PR');
  emit('new-doc-click', {
    query: { action: 'pr' },
    repoId: repoId.value,
    branch: await getBranch(),
  });
}

async function onSnippetsToDocClick() {
  reportOptionSelected('Snippets');
  emit('new-doc-click', {
    query: { action: 'snippets' },
    repoId: repoId.value,
    branch: await getBranch(),
  });
}

function onOpen() {
  analytics.track(productEvents.OPENED_CREATE_ELLIPSIS_MENU, {
    'Repo ID': repoId.value,
    'Workspace ID': workspaceId.value,
  });
}
</script>

<template>
  <SplitButton
    @click="$emit('click')"
    @opened="onOpen"
    data-testid="creation-hub-button"
    :disabled="disabled"
    :options-disabled="optionsDisabled"
  >
    Create
    <template #options>
      <div class="options">
        <EllipsisOption name="Doc (blank)" :handler="onNewDocClick" />
        <EllipsisOption v-if="isPrToDocVisible" name="Doc from PR" :handler="onPrToDocClick">
          <template #appendix v-if="!isGenAIDisabledForWorkspace">
            <Tag text="AI" tag-style="beta" class="ai-tag" />
          </template>
        </EllipsisOption>
        <EllipsisOption v-if="!isGenAIDisabledForWorkspace" name="Doc from code files" :handler="onSnippetsToDocClick">
          <template #appendix v-if="!isGenAIDisabledForWorkspace">
            <Tag text="AI" tag-style="beta" class="ai-tag" />
          </template>
        </EllipsisOption>
        <EllipsisOption v-if="!isProcessToDocDisabledForWorkspace" :name="CreationOptions.Process.label" />
        <EllipsisOption name="Playlist" :handler="onNewPlaylistClick" />
      </div>
    </template>
  </SplitButton>
</template>

<style scoped lang="postcss">
.options {
  min-width: 200px;
}

.ai-tag {
  margin-left: var(--space-base);
  margin-top: -3px;
  margin-bottom: -3px;
}
</style>
